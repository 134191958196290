<template>
<div class="login-container">
    <div class="col login-background" :style="{'background-image': $vuetify.breakpoint.smAndDown ? 'initial' : 'URL('+images.Background+')'}">
        <div class="welcomeText">
            <img v-if="$vuetify.breakpoint.smAndDown == false" :src="images.Logo"/>
            <p><str index="banner.welcome.welcome"/></p>
            <p><str index="banner.welcome.text"/></p>
        </div>
    </div>
    <div class="col px-2">
        <Authenticator @authenticatorReady="$emit('authenticatorReady')" class="login-authenticator"  v-if="!$store.getters.signedIn"/>
        <!-- :options="{social:{Google:true, Facebook:true, LinkedIn:false, Twitter:false}}" -->
    </div>
</div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import Logo from '@/components/Authenticator/assets/logo.png'
import Logo_FR from '@/assets/logo_lh_french.png'
import Background from '@/components/Authenticator/assets/background.png'
import Authenticator from '@/components/Authenticator/Authenticator'
export default {
    name: "Login",
    components: {
        Authenticator,
        Footer
    },
    created: function(){
        if(this.signedIn){
            this.redirect()
        }
    },
    data: function(){
        return {}
    },
    methods: {
        redirect: function(){
            if (this.$router.history._startLocation.includes("/verify")){
                this.$router.push({name:'Home'})
            }
            else if(this.$router.history._startLocation!='/login'){
                this.$router.go(-1)
            }else if(this.$route.params.from && this.$route.params.from!='Login'){
                this.$router.push({name:this.$route.params.from})
            }else{
                this.$router.push({name:'Home'})
            }
        }
    },
    computed: {
        signedIn: function(){
            return this.$store.getters.signedIn
        },
        fullScreen: function(){
            return true
        },
        images: function(){
            return {
                Logo : this.$store.getters.language==='fr-ca' ? Logo_FR : Logo,
                Background
            }
        }
    },
    watch: {
        signedIn: function(){
            if(this.signedIn){
                this.redirect()
            }
        }
    }
}
</script>

<style scoped lang="scss">
.login-container{
    padding: 0px;
    width: 100%;
    display: flex;
    justify-self: stretch;
    justify-content: space-evenly;

    @media(max-width:959px){
        flex-flow: column;
    }
}

.col{
    padding: 0px;
    align-self: stretch;
    width: 50%;
}

.login-background{
    background-size: contain;
    background-position-x: 100%;
    background-color: $secondary;
    padding: 120px 160px 48px 32px;

    @media(max-width:959px){
        padding: 8px 32px;
        border-top: 1px solid white;
    }

    .welcomeText {
        width: 70%;
        margin: 0 auto;

        @media(max-width:959px){
            width: 100%;
            text-align: center;
        }

        p {
            color: white;
        }
        p:nth-of-type(1) {
            margin-top: 16px;
            font-size: 30px;
            @media(max-width:959px){
                font-size: 26px;
                line-height: 28px;
            }
        }
        p:nth-of-type(2) {
            font-size: 20px;
            @media(max-width:959px){
                font-size: 16px;
            }
        }
    }
}
.login-authenticator{
    max-width: 600px;
    width: 100%;
}


.banner-text{
    color: white;
    width: 60%;
    position: relative;
    top: -25px;
    margin: 0 auto;
    margin-top: 50px;
    text-align: left;
    line-height: 35px;
}

    .banner-title{
        font-weight: bold;
        font-size: 30pt;
        margin: 0px 0px 15px 0px;
    }

    .banner-subtitle{
        font-weight: bold;
        line-height: 20px;
    }

@media #{map-get($display-breakpoints, 'sm-and-down')}{
    .col {
        justify-content: center;
        display: flex;
        width: 100%;
    }
}
</style>
